import hotel from '~/images/hotel-solid.svg';
import creditCard from '~/images/credit-card-solid.svg';
import plane from '~/images/plane-departure-solid.svg';
import car from '~/images/car-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label: 'Hotels & rental cars booked through Capital One Travel (10X miles)',
    name: 'hotelsAndRentalCars',
    icon: hotel,
    defaultValue: 6000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Flights booked through Capital One Travel (5X miles)',
    name: 'flights',
    icon: plane,
    defaultValue: 6000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'All other purchases',
    name: 'other',
    icon: creditCard,
    defaultValue: 2400,
    validation: {
      min: 0,
    },
  },
];

export const creditsItems: NumberInputItem[] = [
  {
    label: '$300 Annual credit for bookings through Capital One Travel.',
    name: 'travelCredits',
    icon: plane,
    defaultValue: 300,
    validation: {
      min: 0,
      max: 300,
    },
  },
];

export const perksItems: NumberInputItem[] = [
  {
    label: 'Capital One Lounges',
    name: 'capitalOneLounges',
    icon: plane,
    defaultValue: 100,
    validation: {
      min: 0,
    },
    tooltip:
      'Unlimited complimentary access for you and 2 guests per visit. Cardholders and guests must present a same-day boarding pass for a departing or connecting flight.',
  },
  {
    label: 'Priority Pass lounges',
    name: 'priorityPass',
    icon: plane,
    defaultValue: 50,
    validation: {
      min: 0,
    },
    tooltip: 'Enrollment required. Does not include Priority Pass restaurants.',
  },
  {
    label: 'Access to the Capital One Premier Collection hotels',
    name: 'capitalOnePremierCollection',
    icon: hotel,
    defaultValue: 0,
    validation: {
      min: 0,
    },
    tooltip:
      'Enjoy a $100 experience credit to use during your stay, daily breakfast for 2, and other premium benefits when you book a Premier Collection stay through Capital One Travel.',
  },
  {
    label: "Hertz President's Circle status",
    name: 'hertz',
    icon: car,
    defaultValue: 0,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Ability to add up to 4 authorized users who have lounge perks',
    name: 'authorizedUsers',
    icon: creditCard,
    defaultValue: 0,
    validation: {
      min: 0,
    },
    tooltip:
      'Each authorized user gets a credit card and can access Capital One, Plaza Premium, and Priority Pass lounges.',
  },
  {
    label: 'Other benefits (Cell phone insurance, travel protections, etc.)',
    name: 'otherBenefits',
    icon: creditCard,
    defaultValue: 0,
    validation: {
      min: 0,
    },
  },
];

export const redemptionItems: Redemption[] = [
  { label: 'Statement credit = 0.5 CPM', name: 'cashBack', value: 0.5 },
  { label: 'Gift cards = 1 CPM', name: 'giftCards', value: 1 },
  {
    label: 'Capital One Travel Portal = 1 CPM',
    name: 'capitalOneTravel',
    value: 1,
  },
  {
    label: 'Redeem for recent travel purchases (last 90 days) = 1 CPM',
    name: 'recentTravel',
    value: 1,
  },
  {
    label: 'Transferring to airline partners (target value) = 2 CPM',
    name: 'airlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  creditsItems,
  perksItems,
  redemptionItems,
  annualFee: 395,
};
